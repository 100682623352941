<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <div class="pl-5 pr-5">
      <div>
        <v-row>
          <v-col cols="6" class="pt-0">
            <v-pagination
              class="mt-5"
              style="float: left"
              color="#00BCD4"
              v-model="pagination.current_page"
              :length="pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
          <v-col cols="6" class="pt-0">
            <b-form-input
              class="mt-6"
              style="float: right; width: 300px"
              placeholder="Class Name"
              v-model="data_search"
            ></b-form-input>
          </v-col>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Class Name</th>
                    <th scope="col">Marker(s)</th>
                    <th scope="col">Type</th>
                    <th scope="col">Partner</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(ClassMarker, i) in ClassMarkers">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ ClassMarker.name }}
                        </p>
                      </td>
                      <td class="d-flex align-items-center">
                        <template v-for="(marker, i) in ClassMarker.markers">
                          <v-tooltip bottom :key="i" color="#faebd7">
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                class="d-flex align-items-center"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <div class="symbol symbol-40 symbol-light mr-4">
                                  <span class="symbol-label">
                                    <img
                                      src="media/users/default.jpg"
                                      class="h-75 align-self-end"
                                    />
                                  </span>
                                </div>
                              </div>
                            </template>
                            <div>
                              <p
                                class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                              >
                                {{ marker.name }}
                              </p>
                              <span
                                class="text-muted font-weight-bold d-block"
                                >{{ marker.email }}</span
                              >
                            </div>
                          </v-tooltip>
                        </template>
                      </td>
                      <td>
                        <span
                          v-if="ClassMarker.for_partners_only == 1"
                          class="label label-lg label-inline font-weight-bold py-4 label-light-success"
                          >Đối tác</span
                        >
                        <span
                          v-else
                          class="label label-lg label-inline font-weight-bold py-4 label-light-warning"
                          >Bình thường</span
                        >
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          v-if="ClassMarker.for_partners_only == 1"
                        >
                          {{ ClassMarker.partner }}
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-success btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="openDialogAddMarker(ClassMarker)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-account-multiple-plus</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Add Marker</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-primary btn-sm ml-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="openDialogConfigDeadline(ClassMarker)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-timer</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Cấu hình chấm, nộp bài</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!ClassMarkers.length">
                    <tr>
                      <td style="text-align: center" colspan="5">No data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
          <v-col cols="6">
            <v-pagination
              style="float: left"
              color="#00BCD4"
              v-model="pagination.current_page"
              :length="pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialogAddMarker" max-width="1000px" scrollable>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Add Marker</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogAddMarker = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <label style="font-size: 16px">Markers</label>
                  <v-autocomplete
                    v-model="marker_ids"
                    :disabled="isUpdating"
                    :items="marker_tags"
                    filled
                    chips
                    color="blue-grey lighten-2"
                    multiple
                    item-text="name"
                    item-value="id"
                    @input="selectMarker"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click:close="removeMarkerTag(data.item)"
                        @click="data.select"
                      >
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content
                          v-text="data.item"
                        ></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <label style="font-size: 16px"
                    >Danh sách quản lí skill của các marker</label
                  >
                  <v-card class="">
                    <v-tabs
                      background-color="#f0f0f0"
                      dark
                      v-model="tab_assign"
                    >
                      <v-tabs-slider color="indigo"></v-tabs-slider>
                      <v-tab
                        v-for="(
                          item_assign_marker, iItem
                        ) in data_assign_marker"
                        :key="iItem"
                        style="color: #3f51b5"
                      >
                        <span class="font-weight-bold">{{
                          item_assign_marker.marker_name
                        }}</span>
                      </v-tab>
                    </v-tabs>

                    <v-tabs-items
                      vertical
                      v-model="tab_assign"
                      v-if="data_assign_marker.length > 0"
                      style="min-height: 300px"
                    >
                      <v-tab-item
                        v-for="(item_comment, iItem) in data_assign_marker"
                        :key="iItem"
                      >
                        <v-card flat>
                          <ul>
                            <template
                              v-for="(skill, iSkill) in item_comment.skills"
                            >
                              <li :key="iSkill">
                                <v-checkbox
                                  dense
                                  v-model="skill.checked"
                                  :label="`${skill.name.toString()}`"
                                ></v-checkbox>
                              </li>
                            </template>
                          </ul>
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                    <v-tabs-items
                      vertical
                      v-model="tab_assign"
                      v-if="data_assign_marker.length <= 0"
                      style="min-height: 250px"
                    >
                      <p style="text-align: center; margin-top: 100px">
                        No data
                      </p>
                    </v-tabs-items>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              @click="btnAddMarker"
            >
              Add
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogAddMarker = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <ConfigDeadlineForm
      :show_dialog="dialogDeadlineConfig"
      @setDialog="(e) => (this.dialogDeadlineConfig = e)"
      :data_class="data_class"
      @resetDataClass="getAllMarkerClass(pagination.current_page)"
    ></ConfigDeadlineForm>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
import handle_ls_search from "@/mixins/handle_ls_search.js";
import ConfigDeadlineForm from "./Modal/ConfigDeadlineForm";

export default {
  name: "ClassList",
  mixins: [handle_ls_search],
  components: {
    ConfigDeadlineForm,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      data_search: "",
      ClassMarkers: [],
      pagination: {
        current_page: 1,
        total_pages: 1,
      },
      marker_tags: [],
      marker_ids: [],
      isUpdating: false,
      dialogAddMarker: false,
      class_id: null,
      timer_query: null,
      skill_tags: [],
      tab_assign: 0,
      data_assign_marker: [],
      dialogDeadlineConfig: false,
      data_class: {},
    };
  },
  created() {
    if (this.canAccess("assign-marker-for-class")) {
      //this.is_call_api = true;
      let data_search = this.getDataSearchByKey("assign_marker_class");
      if (data_search) {
        this.data_search = data_search.query ? data_search.query : "";
      }
      this.getAllMarkerClass();
      this.getListMarker();
      this.getAllSkill();
      //this.is_call_api = false;
    }
  },

  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
    "pagination.current_page": function (page) {
      this.getAllMarkerClass(page, this.data_search);
    },
    data_search(val) {
      this.actionSearchClass(1, val);
    },
  },
  methods: {
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    getAllMarkerClass(page = 1, query = "") {
      let vm = this;
      vm.is_call_api = true;
      ApiService.get(
        "prep-app/marker/class-marker?page=" + page + "&query=" + query
      ).then(function (response) {
        vm.ClassMarkers = response.data.data;
        vm.pagination = response.data.meta.pagination;
        vm.is_call_api = false;
      });
    },
    getListMarker() {
      let self = this;
      ApiService.get("prep-app/markers").then(function (response) {
        if (response.status === 200) {
          self.marker_tags = response.data;
        }
      });
    },
    async getAllSkill() {
      let seft = this;
      await ApiService.get("prep-app/tag/list-skill").then(function (response) {
        if (response.status === 200) {
          seft.skill_tags = response.data;
        }
      });
    },
    removeMarkerTag(item) {
      let index = this.marker_ids.indexOf(item.id);
      if (index >= 0) {
        this.marker_ids.splice(index, 1);
        let indexMarker = this.data_assign_marker.findIndex(
          (el) => el.marker_id === item.id
        );
        this.data_assign_marker.splice(indexMarker, 1);
      }
    },
    openDialogAddMarker(ClassMarker) {
      let vm = this;
      this.class_id = ClassMarker.id;
      this.marker_ids = ClassMarker.markers.map((teacher) => {
        return teacher.id;
      });
      this.data_assign_marker = ClassMarker.markers.map((marker) => {
        return {
          marker_id: marker.id,
          marker_name: marker.name,
          skills: vm.skill_tags.map((skill) => {
            let marker_ids = marker.skills.map((item) => item.id);
            return {
              id: skill.id,
              name: skill.name,
              checked: marker_ids.includes(skill.id),
            };
          }),
        };
      });
      this.dialogAddMarker = true;
    },
    btnAddMarker() {
      let vm = this;
      vm.is_call_api = true;
      let data = {
        class_id: this.class_id,
        marker_ids: this.marker_ids,
        data_assign_marker: this.data_assign_marker,
      };

      ApiService.post("prep-app/marker/class-marker/update", data)
        .then(function (res) {
          if (res.status === 202) {
            vm.getAllMarkerClass(vm.pagination.current_page);
            vm.dialogAddMarker = false;
            vm.is_call_api = false;
            Swal.fire({
              title: "",
              text: "The application has been successfully submitted!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          }
        })
        .catch(function (error) {
          if (error) {
            vm.is_call_api = false;
            vm.$toasted.error(
              "Something Wrong. Please try again or contact admin !!",
              {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              }
            );
          }
        });
    },
    actionSearchClass(page = 1, query = "") {
      let vm = this;
      if (this.timer_query) {
        clearTimeout(this.timer_query);
        this.timer_query = null;
      }
      this.timer_query = setTimeout(() => {
        let assign_marker_class = {
          query: query,
        };
        vm.saveDataSearchToLs("assign_marker_class", assign_marker_class);
        vm.getAllMarkerClass(page, query);
      }, 1200);
    },
    selectMarker(e) {
      let vm = this;
      let marker_id_selected = e;
      let marker_id_olds = this.data_assign_marker.map(function (item) {
        return item["marker_id"];
      });
      let marker_id_news = marker_id_selected.filter(function (id) {
        return !marker_id_olds.includes(id);
      });
      let skills = this.skill_tags.map(function (item) {
        return {
          id: item["id"],
          name: item["name"],
          checked: true,
        };
      });
      marker_id_news.forEach(function (id_new) {
        let marker_filtered = vm.marker_tags.filter((el) => el.id === id_new);
        let data = {
          marker_id: marker_filtered[0]["id"],
          marker_name: marker_filtered[0]["name"],
          skills: skills,
        };
        vm.data_assign_marker.push(data);
      });
    },
    openDialogConfigDeadline(ClassMarker) {
      this.dialogDeadlineConfig = true;
      this.data_class = ClassMarker;
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.email-marker {
  font-style: italic;
  font-weight: bold;
  color: gray;
}
.table td {
  vertical-align: middle;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
.label.label-light-success {
  color: #1479f4;
  background-color: #e1f0ff;
}
ul li {
  list-style-type: none;
}
</style>
