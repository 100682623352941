<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
        v-model="dialogDeadlineConfig"
        max-width="1400px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Cấu hình nộp, chấm bài</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogDeadlineConfig = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"></v-col>
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><label class="text-h6 font-weight-bolder"
                        >Cấu hình thời gian nộp bài:</label
                      ></v-col
                    >
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Sử dung:</label></v-col
                    >
                    <v-col cols="1" class="p-0 m-0 ml-4">
                      <v-checkbox
                        v-model="has_submit_deadline_config_input"
                        class="mt-0 pt-1"
                        dense
                        color="#5cbbf6"
                        hide-details
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6"
                        >Hạn ngày nộp bài trong tuần:</label
                      ></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-autocomplete
                        dense
                        filled
                        :readonly="!has_submit_deadline_config_input"
                        :items="days"
                        item-text="label"
                        item-value="value"
                        v-model="deadline_submit_day_of_week_input"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Thời gian nộp bài:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <date-picker
                        class=""
                        value-type="format"
                        format="HH:mm:ss"
                        :disabled="!has_submit_deadline_config_input"
                        v-model="deadline_submit_time_input"
                        type="time"
                      />
                      <label class="text-h6 ml-4">(Giờ/ phút/ giây)</label>
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"></v-col>
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><label class="text-h6 font-weight-bolder"
                        >Cấu hình thời gian chấm bài:</label
                      ></v-col
                    >
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Sử dung:</label></v-col
                    >
                    <v-col cols="1" class="p-0 m-0 ml-4">
                      <v-checkbox
                        v-model="has_marking_deadline_config_input"
                        class="mt-0 pt-1"
                        dense
                        color="#5cbbf6"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6"
                        >Hạn ngày chấm bài trong tuần:</label
                      ></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-autocomplete
                        dense
                        filled
                        :readonly="!has_marking_deadline_config_input"
                        :items="days"
                        item-text="label"
                        item-value="value"
                        v-model="deadline_marking_day_of_week_input"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Thời gian chấm bài:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <date-picker
                        class=""
                        value-type="format"
                        format="HH:mm:ss"
                        :disabled="!has_marking_deadline_config_input"
                        v-model="deadline_marking_time_input"
                        type="time"
                      />
                      <label class="text-h6 ml-4">(Giờ/ phút/ giây)</label>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              @click="btnUpdateDeadline"
            >
              Lưu
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogDeadlineConfig = false"
            >
              Thoát
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
export default {
  name: "ConfigDeadlineForm",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    type_form: {
      type: String,
      default: "",
    },
    data_class: {},
  },
  data() {
    return {
      is_call_api: false,
      class_id: null,
      days: [
        { label: "Thứ 2", value: 1 },
        { label: "Thứ 3", value: 2 },
        { label: "Thứ 4", value: 3 },
        { label: "Thứ 5", value: 4 },
        { label: "Thứ 6", value: 5 },
        { label: "Thứ 7", value: 6 },
        { label: "Chủ nhật", value: 0 },
      ],
      deadline_submit_day_of_week_input: null,
      deadline_submit_time_input: null,
      deadline_marking_day_of_week_input: null,
      deadline_marking_time_input: null,
      has_submit_deadline_config_input: true,
      has_marking_deadline_config_input: true,
    };
  },
  computed: {
    dialogDeadlineConfig: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    has_submit_deadline_config_input(val) {
      if (!val) {
        this.deadline_submit_time_input = null;
        this.deadline_submit_day_of_week_input = null;
      }
    },
    has_marking_deadline_config_input(val) {
      if (!val) {
        this.deadline_marking_time_input = null;
        this.deadline_marking_day_of_week_input = null;
      }
    },
    dialogDeadlineConfig(val) {
      if (val) {
        this.setDataForm();
      }
    },
  },
  methods: {
    setDataForm() {
      this.class_id = this.data_class.id;
      this.deadline_submit_day_of_week_input =
        this.data_class.deadline_submit_day_of_week;
      this.deadline_submit_time_input = this.data_class.deadline_submit_time;
      this.deadline_marking_day_of_week_input =
        this.data_class.deadline_marking_day_of_week;
      this.deadline_marking_time_input = this.data_class.deadline_marking_time;
      this.has_submit_deadline_config_input =
        this.data_class.has_submit_deadline_config === 1;
      this.has_marking_deadline_config_input =
        this.data_class.has_marking_deadline_config === 1;
    },
    validateData() {
      let flat = true;
      if (this.has_submit_deadline_config_input) {
        if (this.deadline_submit_day_of_week_input == null) {
          this.$toasted.error("Chưa nhập ngày nộp bài !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
        if (this.deadline_submit_time_input == null) {
          this.$toasted.error("Chưa nhập thời gian nộp bài !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      if (this.has_marking_deadline_config_input) {
        if (this.deadline_marking_day_of_week_input == null) {
          this.$toasted.error("Chưa nhập ngày chấm bài !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
        if (this.deadline_marking_time_input == null) {
          this.$toasted.error("Chưa nhập thời gian chấm bài !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      return flat;
    },
    btnUpdateDeadline() {
      let vm = this;
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let data = {
        class_id: this.class_id,
        has_submit_deadline_config: this.has_submit_deadline_config_input
          ? 1
          : 0,
        has_marking_deadline_config: this.has_marking_deadline_config_input
          ? 1
          : 0,
      };
      if (this.deadline_submit_day_of_week_input)
        data.deadline_submit_day_of_week =
          this.deadline_submit_day_of_week_input;
      if (this.deadline_submit_time_input)
        data.deadline_submit_time = this.deadline_submit_time_input;
      if (this.deadline_marking_day_of_week_input)
        data.deadline_marking_day_of_week =
          this.deadline_marking_day_of_week_input;
      if (this.deadline_marking_time_input)
        data.deadline_marking_time = this.deadline_marking_time_input;
      this.is_call_api = true;
      ApiService.post("prep-app/marker/class-marker/update-deadline", data)
        .then(function (res) {
          if (res.status === 200) {
            vm.$toasted.success("Cập nhập thành công thành công !!", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
            vm.$emit("resetDataClass");
            vm.is_call_api = false;
            vm.dialogDeadlineConfig = false;
          }
        })
        .catch(function (error) {
          vm.is_call_api = false;
          if (error.response.status === 422) {
            let data_response_error =
              error.response.data.error.message.split(".<br />");
            vm.showError(data_response_error);
          }
        });
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
  },
};
</script>

<style scoped></style>
